$purple: #6a2c70;
$light-purple: rgba(106, 44, 112, 0.85);
$border-black: rgba(0, 0, 0, 0.2);
$border-grey: #eeeeee;
$white: #FFFFFF;
$light-white: rgba(255, 255, 255, 0.85);
$text-grey: #2e3d49;
$text-grey-2: #555555;
$text-grey-3: #525c65;
$blue: #29a8cd;
$active-blue: #1176ae;
$yellow: #fbbc05;
$pink: #e65c62;
$green: #34a853;
$green-2: #28ae5d;
$orange: #d93d04;