.dark {
    .top {
        span {
            color: #555555;
        }
    }
}
.light {
    .top {
        span {
            color: #ffffff;
        }
    }
}

.wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;

    .top {
        display: flex;
        align-items: center;
        justify-content: space;
        min-width: 150px;
        .image {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                margin: 0;
                color: rgba(1, 1, 1, 0.4);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 100%;
            }
        }
        .name {
            display: flex;
            align-items: center;

            svg {
                color: #555555;
            }
        }
    }

    .dropdownWrapper {
        position: absolute;
        top: 50px;
        left: 0;
        width: 100%;
        border-radius: 0px;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.06);
        border: solid 1px #eeeeee;
        background-color: #ffffff;
        max-width: 300px;

        .menu {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 5px 0;
            cursor: pointer;
            padding: 5px 10px;
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0;
            }
            &:hover {
                background: #eeeeee;
            }
            svg {
                width: 20px;
                stroke: #30475e;
                margin-right: 15px;
            }
            p {
                font-size: 14px;
                color: #30475e;
            }
        }
    }
    svg {
        stroke-width: 2px;
        margin-left: 5px;
    }
}

@media (max-width: 800px) {
    .mobileNavigation {
        .nav {
            cursor: pointer;
            margin-bottom: 40px;
            &:first-child {
                margin-top: 50px;
            }
            p {
                transition: all 0.5s;
                opacity: 0;
                font-size: 18px;
                font-weight: 500;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    width: 20px;
                    margin-left: 5px;
                }
            }
        }
    }
}
